import NavItem from "./NavItem";

interface INavItemList {
  role: string
}

const NavItemList = ({ role }: INavItemList) => {
  switch (role) {
    case "Administrator":
      return (
        <ul className="nav flex-column">
          <NavItem label="Dashboard" icon="home" href="/administrator/dashboard"></NavItem>
          <NavItem label="Customers" icon="users" href="/administrator/customers"></NavItem>
          <NavItem label="Current sign-ins" icon="network-wired" href="/administrator/signins"></NavItem>  
          <NavItem label="Jobs" icon="person-digging" href="/administrator/jobs"></NavItem> 
          <NavItem label="Documents" icon="upload" href="/administrator/documents"></NavItem> 
        </ul>
      )

    case "Customer":
      return (
        <ul className="nav flex-column">
          <NavItem label="Dashboard" icon="home" href="/customer/dashboard"></NavItem>
          <NavItem label="Documents" icon="upload" href="/customer/documents"></NavItem>
        </ul>
      )
    
    default: 
        return <></>
  }
}

export default NavItemList
import { DocumentContext } from "contexts/DocumentContext";
import { useEffect, useState, useCallback, useMemo } from "react"
import {useDropzone} from "react-dropzone"
import { Document } from "models/Document"
import React from "react";
import DocumentList from "components/DocumentList";
import CommandBar from "components/CommandBar";
import { exportDocuments } from "helpers/exporter";
import { useOutletContext } from "react-router";
import PropertyBar from "components/PropertyBar";
import TextInput from "components/TextInput";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { ErrorCode } from "helpers/errorcodes";
import Icon from "components/Icon";
import configSettings from "settings/config.json";

const MAX_SIZE = 50 * 1024 * 1024;

const Documents = () => {  
  const [documents, setDocuments] = useState<Document[]>();
  const [editDocument, setEditDocument] = useState<Document>(Object);  
  const [isPropertyBarVisible, setIsPropertyBarVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const { getAll, update, upload, download } = DocumentContext();

  const logout = useOutletContext();

  const onDrop = useCallback(async (acceptedFiles: any, fileRejections: any) => {
      var isValid = true;

      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: any) => {
          if (err.code === "file-too-large") {
            setErrorMessage(`Please upload a file that is less than ${MAX_SIZE / 1048576} MB in size.`);
            isValid = false;
          }

          if (err.code === "file-invalid-type") {
            setErrorMessage(`Please upload a PDF file.`);
            isValid = false;
          }
        });
      });

      if (isValid) {
        setErrorMessage("");
        await uploadDocument(acceptedFiles[0]);
        acceptedFiles.splice(acceptedFiles[0], 1);
      }
  }, []);

  const {acceptedFiles, getRootProps, getInputProps, open, isDragActive} = useDropzone({ onDrop, 
    multiple: false,
    accept: {
      "application/pdf": [".pdf", ".PDF"]
    }, maxSize: MAX_SIZE });
    
      
  const files = acceptedFiles.map(file => (
    <>
      {file.name} - {file.size} bytes
    </>    
  ));

  useEffect(() => {    
    const asyncGetDocuments = async () => {
      await getAll()
      .then(result => {
        setDocuments(result.data);
      })
      .catch(error => {
        console.log(JSON.stringify(error));        
      });   
    }

    asyncGetDocuments();
  }, []);

  const updateProgress = (progress: any) => {
    setUploadProgress(progress);
  };
  
  const uploadDocument = async(file: any) => {
    await upload(file, updateProgress)
    .then(async result => {      
      await getAll()
      .then(result => {
        setUploadProgress(0);
        setDocuments(result.data);
      })
      .catch(error => {
        console.log(JSON.stringify(error));        
      });   
    })
    .catch(error => {
      switch(error?.response?.data?.errorCode) {
        case ErrorCode.DocumentAlreadyExists:
          setErrorMessage("A document with this name already exists.");
          break;
        default:
          if (error?.response?.data !== undefined) 
            setErrorMessage(error.response.data.message);
          else
            setErrorMessage(error.toString());   
          break;
      }            
    });
  }

  const handleRowClick = (clickedDocument: Document) => {
    setEditDocument({ ...clickedDocument });
    setIsPropertyBarVisible(true);
  }

  const handleCancel = () => {
    setIsPropertyBarVisible(false);
    setTimeout(() => { setEditDocument(new Document()); }, 500)
  }

  const handleDocumentUpdate = async () => {
    await update(editDocument);
    var documents = await getAll();
    setDocuments(documents.data);
    // handleSearchTermsDebounce(""); 
  }

  const updateDocumentProperty = (prop: string, value: string, type: string) => {  
    switch (type) {      
      default:
        (editDocument as any)[prop] = value;
        break; 
    }    
  }

  const fields = useMemo(
    () => [          
      {
        label: "Name",
        accessor: "name",
        type: "text",
        required: true
      },
      {
        label: "Notes",
        accessor: "notes",
        type: "textArea"
      },
      {
        label: "Unique ID",
        accessor: "uniqueID",
        type: "text",
        fixed: true
      },
      {
        label: "Hash",
        accessor: "hash",
        type: "text",
        fixed: true
      },
      {
        label: "Size",
        accessor: "size",
        type: "text",
        fixed: true
      },     
      {
        label: "Account email address",
        accessor: "accountEmailAddress",
        type: "text",
        fixed: true
      },
      {
        label: "Uploaded timestamp",
        accessor: "uploadedTimestamp",
        type: "dateTime",
        fixed: true
      }      
    ],
    [],
  );

  return (
    <main>   
    <Rodal visible={errorMessage.length > 0} showCloseButton={false} onClose={() => {return;}}>
      <div className="rodal-title">Upload failed</div>
      <div className="rodal-body">{errorMessage}</div>
      <button className="styled-button" onClick={() => setErrorMessage("")}>Close</button>
    </Rodal>                 
    <CommandBar onExport={() => exportDocuments(documents!)} onLogout={logout}></CommandBar>        
    <div id="overlay" className="wrapper">  
     <div className="header">Documents</div>            
      <div className="inner">
        <DocumentList documents={documents} handleRowClick={handleRowClick} showAccountInfo={true}></DocumentList> 
      </div>
      <PropertyBar entityID={editDocument?.uniqueID?.toString()} isVisible={isPropertyBarVisible} onSave={handleDocumentUpdate} onCancel={handleCancel}>
        <>                  
          <div className="caption">{"Document info"} 
            <a href={`${configSettings.ApiRootUrl}/document/${editDocument.uniqueID}`} download={editDocument.name}><Icon name="download" /></a>
          </div>
          {fields.map((o, i) => {          
            return <TextInput
              entityID={editDocument?.uniqueID?.toString()}
              key={o.accessor}
              type={o.type}
              label={o.label}
              name={o.accessor}
              value={(editDocument as any)[o.accessor]}
              required={o.required ?? false}
              fixed={o.fixed ?? false}
              onChange={(value: string) => updateDocumentProperty(o.accessor, value, o.type)} />
          })}
        </>
      </PropertyBar>
      <div className="drop-pad" {...getRootProps()}>
       <input {...getInputProps()} />
          <p>Add document</p>
          <> 
          {       
            files.length > 0 && <React.Fragment>
            <div className="file-item">               
               {files[0]}
            </div>
            </React.Fragment>
          }
          </>       
      </div>  
      <div className="upload-progress-bar">
        <span style={{ width: `${uploadProgress}%`}}></span>
      </div>
    </div>
    </main>
  );
}

export default Documents


import Table from "components/Table";
import { useEffect, useState, useCallback, useMemo } from "react"
import { Document } from "models/Document"

interface IDocumentList {
  documents: Document[] | undefined,
  handleRowClick: Function,
  showAccountInfo: boolean
}

const DocumentList = ({ documents, handleRowClick, showAccountInfo }: IDocumentList) => {
  const columns = useMemo(
    () => [           
      {
        label: "Name",
        accessor: "name"
      },        
      {
        label: "Size",
        accessor: "size",
        type: "fileSize"
      },          
      ... showAccountInfo === false ? [] : 
      [{
        label: "Account",
        accessor: "accountEmailAddress",
      }],    
      {
        label: "Uploaded",
        accessor: "uploadedTimestamp",
        type: "datetime"
      }     
    ],
    [],
  );
  
  return ( 
    <Table
      id="documents-table"
      type="documents"
      keyField="uniqueID"
      columns={columns}
      sourceData={documents}
      isPropertyBarVisible={false}
      initialSortColumn={"uploadedTimestamp"}
      initialSortOrder={"desc"}
      onSearchTermsChange={null}
      onRowClick={handleRowClick}>
    </Table>      
  );
}

export default DocumentList
import { createContext, useContext, useState } from "react"
import { AxiosResponse } from "axios";
import jwt from "jwt-decode"
import { ErrorCode } from "helpers/errorcodes"
import { Customer } from "models/Customer";
import { axiosRequest } from "api/api";

interface ICustomerContext {
  register: (companyName: string, firstName: string, middleName: string, lastName: string, emailAddress: string, password: string) => Promise<AxiosResponse<any, any>>,
  registerGoogle: (credential: string, nonce: string) => Promise<string>,
  // search: (terms: string) => Promise<AxiosResponse<Customer[], any>>,
  getAll: () => Promise<AxiosResponse<Customer[], any>>,
  update: (customer: Customer) => Promise<AxiosResponse<any, any>>,
}

export const CustomerContext = (): ICustomerContext => {
  const {
    register,
    registerGoogle,
    getAll,
    update
  } = useContext(Context);

  return {
    register,
    registerGoogle,
    getAll,
    update
  };
}
    
const Context = createContext({} as ICustomerContext);

export function CustomerProvider({ children }: { children: any }) {

  const register = async (companyName: string, firstName: string, middleName: string, lastName: string, emailAddress: string, password: string): Promise<AxiosResponse<any, any>> => {
    if (password.length == 0) {
      return await axiosRequest.post("/customer/register",
        JSON.stringify({ 
          firstName,
          middleName,
          lastName,         
          companyName,
          emailAddress
        }));             
    }
    else {
      return await axiosRequest.post("/customer/register",
        JSON.stringify({ 
          firstName,
          middleName,
          lastName,         
          companyName,
          emailAddress,
          password
        }));   
    }
  }

  const registerGoogle = async (credential: string, nonce: string): Promise<string> => {
    const item = jwt<any>(credential);

    await axiosRequest.post("/customer/register",
      JSON.stringify({ 
        firstName: item.given_name,
        lastName: item.family_name,
        emailAddress: item.email,
        googleCredential: credential
      })   
    ).then(async result => {
      if (nonce != item.nonce) {
        throw { response: { data: { errorCode: 2201, errorCodeName: ErrorCode.GoogleJwtNonceInvalid } } };
      }
      return "";
    }
    ).catch(error => { throw error;});
    return ""; 
  }

  const getAll = async (): Promise<AxiosResponse<Customer[], any>> => {
    return await axiosRequest.get("/customer/all"); 
  }

  const search = async (terms: string): Promise<AxiosResponse<Customer[], any>> => {
    return await axiosRequest.post("/customer/all",
      JSON.stringify({ 
        terms 
      }));     
  }

  const update = async (customer: Customer): Promise<AxiosResponse<any, any>> => {    
    if (customer.uniqueID != undefined)
      return await axiosRequest.put(`/customer/${customer.uniqueID}`, customer);   
    else
      return await axiosRequest.post("/customer", customer);         
  }
  
  return (
    <Context.Provider value={{
      register,
      registerGoogle,
      getAll,
      update      
    }}>{children}
    </Context.Provider>
  )
}

import { Guid } from "typescript-guid";

export class Customer {
    public uniqueID: Guid;
    public emailAddress: string;
    public internalID: string;
    public firstName: string;
    public middleName?: string;
    public lastName: string;
    public companyName?: string
    public address: string;
    public address2?: string;
    public city: string;
    public state: string; 
    public postalCode: string;
    public fullName: string;
    public lastActiveTimestamp: Date | null;
}


import { createContext, useContext, useState } from "react"
import { AxiosResponse } from "axios";
import { axiosRequest } from "api/api";
import { Document, DocumentUpdate } from "models/Document"
import configSettings from "settings/config.json";
import { Guid } from "typescript-guid";

interface IDocumentContext {
  getAll: () => Promise<AxiosResponse<Document[], any>>,
  upload: (content: File, onProgressUpdate: Function) => Promise<AxiosResponse<any, any>>,
  update: (document: DocumentUpdate) => Promise<AxiosResponse<any, any>>,
  download: (uniqueID: Guid, name: string) => Promise<any>
}

export const DocumentContext = (): IDocumentContext => {
  const {
    getAll,
    upload,
    update,
    download
  } = useContext(Context);

  return {
    getAll,
    upload,
    update,
    download
  };
}
    
const Context = createContext({} as IDocumentContext);
const apiRootUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : configSettings.ApiRootUrl;

export function DocumentProvider({ children }: { children: any }) {

  const getAll = async (): Promise<AxiosResponse<Document[], any>> => {
    return await axiosRequest.get("/document/all");             
  }

  const upload = async (document: File, onProgressUpdate: Function): Promise<AxiosResponse<any, any>> => {
    let formData = new FormData();
    formData.append('document', document);
    
    const config = {
      onUploadProgress: (progressEvent: { loaded: any; }) => {
        const progress = 100.0 * progressEvent.loaded / document.size;
        onProgressUpdate(progress);
      }
    }

    return await axiosRequest.put("/document", formData, config);
  }

  const update = async (document: DocumentUpdate): Promise<AxiosResponse<any, any>> => {   
    return await axiosRequest.post(`/document/${document.uniqueID}`, {
      "name": document.name, 
      "notes": document.notes 
   });
  }

  const download = async (uniqueID: Guid, name: string): Promise<any> => {   
    const request = new XMLHttpRequest();
    request.responseType = "blob";
    request.open("GET", `${apiRootUrl}/document/${uniqueID}`, true);
    request.withCredentials = true;
    request.setRequestHeader("Cross-Origin-Opener-Policy", "same-origin-allow-popups");

    //request.setRequestHeader("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJlNDg4NGNiZC02OGNkLTRlN2MtYTk4OS00OWQ2MGZhN2YxZmEiLCJBY2NvdW50SUQiOiJBcXB5dXhMWFhBNFhzd1k4S0J5eDJ2dEFaS0xzTEIzZm5ZcU1YOC9UbWNjPSIsIkVtYWlsQWRkcmVzcyI6ImFkbWluaXN0cmF0b3JAdmVldi5haSIsIlJvbGUiOiJBZG1pbmlzdHJhdG9yIiwiUmVmcmVzaFRva2VuIjoiNzg3OThiNDgtMzA5NC00YmYyLTg5NmEtYzEwNGNlMDdhNzc0IiwibmJmIjoxNjk3MzM4Njc5LCJleHAiOjE2OTczMzkyNzksImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3QifQ.K8ila-CBvQK1n6bpOJlojuasdjgYhfWeSwAWoDG2qJg");
    request.send();

    request.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        const imageURL = window.URL.createObjectURL(this.response);
        const anchor = document.createElement("a");
        anchor.href = imageURL;
        anchor.download = name;
        document.body.appendChild(anchor);
        anchor.click();
      }
    }

    return "";
  }

  return (
    <Context.Provider value={{
      getAll,
      upload,
      update,
      download
    }}>{children}
    </Context.Provider>
  )
}

import { LandingLayout } from "layouts/LandingLayout"
import { Link, useNavigate } from "react-router-dom"

const Index = () => {
 
  const navigate = useNavigate();

  return (
    <LandingLayout id="index" image="landing.jpg">  
    <div className="logo-container">
      <img className="logo" src="logo-white.png"></img>
      <Link className="styled-button signin-button" to={"/signin"} >Sign in</Link>      
    </div>
    <div className="container">
      <div className="content">
        <div className="title">AI-FUELED INSIGHT</div>
        <div className="subtitle">veev AI integrates securely with your private datastores,<br/>continuously ingests new and updated files,<br/>and provides a simple chatbot interface for semantic search.</div>
        <div className="button-row">
          <Link className="styled-button" to={"/register"} >Register Now</Link>
        </div>        
      </div>
     </div>
    </LandingLayout>
  )
}

export default Index
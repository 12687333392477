import { Guid } from "typescript-guid";

export class Document {
    public uniqueID: Guid;
    public name: string;
    public type: string;
    public extension: string;
    public hash: string;
    public size: number;
    public notes: string;
    public uploadedTimestamp: string;
    public accountUniqueID: Guid;
    public accountEmailAddress: string;
}

export class DocumentUpdate {
    public uniqueID: Guid;
    public name: string;
    public notes: string;
}
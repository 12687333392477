import { DocumentContext } from "contexts/DocumentContext";
import { useEffect, useMemo, useState } from "react"
import { Document } from "models/Document"
import React from "react";
import DocumentList from "components/DocumentList";
import CommandBar from "components/CommandBar";
import { useOutletContext } from "react-router";
import { exportDocuments } from "helpers/exporter";
import TextInput from "components/TextInput";
import PropertyBar from "components/PropertyBar";
import configSettings from "settings/config.json";
import Icon from "components/Icon";

const Documents = () => {  
  const [documents, setDocuments] = useState<Document[]>();
  const [editDocument, setEditDocument] = useState<Document>(Object);  
  const [isPropertyBarVisible, setIsPropertyBarVisible] = useState(false);
  const { getAll, update, download } = DocumentContext();
  
  const logout = useOutletContext();

  useEffect(() => {    
    const asyncGetDocuments = async () => {
      await getAll()
      .then(result => {
        setDocuments(result.data);
      })
      .catch(error => {
        console.log(JSON.stringify(error));        
      });   
    }

    asyncGetDocuments();
  }, []);

  const handleRowClick = (clickedDocument: Document) => {
    setEditDocument({ ...clickedDocument });
    setIsPropertyBarVisible(true);
  }

  const handleCancel = () => {
    setIsPropertyBarVisible(false);
    setTimeout(() => { setEditDocument(new Document()); }, 500)
  }

  const handleDocumentUpdate = async () => {
    await update(editDocument);
    var documents = await getAll();
    setDocuments(documents.data);
    // handleSearchTermsDebounce(""); 
  }

  const updateDocumentProperty = (prop: string, value: string, type: string) => {  
    switch (type) {      
      default:
        (editDocument as any)[prop] = value;
        break; 
    }    
  }

  const fields = useMemo(
    () => [          
      {
        label: "Name",
        accessor: "name",
        type: "text",
        required: true
      },
      {
        label: "Notes",
        accessor: "notes",
        type: "textArea"
      },
      {
        label: "Unique ID",
        accessor: "uniqueID",
        type: "text",
        fixed: true
      },
      {
        label: "Hash",
        accessor: "hash",
        type: "text",
        fixed: true
      },
      {
        label: "Size",
        accessor: "size",
        type: "text",
        fixed: true
      },     
      {
        label: "Account email address",
        accessor: "accountEmailAddress",
        type: "text",
        fixed: true
      },
      {
        label: "Uploaded timestamp",
        accessor: "uploadedTimestamp",
        type: "dateTime",
        fixed: true
      }      
    ],
    [],
  );

  return (
    <main>                    
    <CommandBar onExport={() => exportDocuments(documents!)} onLogout={logout}></CommandBar>        
    <div id="overlay" className="wrapper">  
     <div className="header">Documents</div>            
      <div className="inner">
        <DocumentList documents={documents} handleRowClick={handleRowClick} showAccountInfo={true}></DocumentList> 
      </div>
      <PropertyBar entityID={editDocument?.uniqueID?.toString()} isVisible={isPropertyBarVisible} onSave={handleDocumentUpdate} onCancel={handleCancel}>
        <>                  
          <div className="caption">{"Document info"} 
            <a href={`${configSettings.ApiRootUrl}/document/${editDocument.uniqueID}`} download={editDocument.name}><Icon name="download" /></a>
          </div>
          {fields.map((o, i) => {          
            return <TextInput
              entityID={editDocument?.uniqueID?.toString()}
              key={o.accessor}
              type={o.type}
              label={o.label}
              name={o.accessor}
              value={(editDocument as any)[o.accessor]}
              required={o.required ?? false}
              fixed={o.fixed ?? false}
              onChange={(value: string) => updateDocumentProperty(o.accessor, value, o.type)} />
          })}
        </>
      </PropertyBar>
    </div>
    </main>
  );
}

export default Documents


export enum ErrorCode {
  Unknown = 1000,
  Unhandled = 1001,
  NullReference = 1002,
  AccessDenied = 1003,
  NotImplemented = 1004,
  TimedOut = 1005,
  EntityDoesNotExist = 1006,
  InvalidRequest = 1007,
  FileNotFound = 1008,
  EntityFrameworkError = 1009,
  RequestParameterNotExpectedInQueryString = 1010,
  RequiredParameterNullOrEmpty = 1011,
  ParameterCouldNotBeParsed = 1012,
  ParameterCouldNotBeParsedToEnum = 1013,
  RateLimitExceeded = 1014,
  Blacklisted = 1015,
  EnumValueInvalid = 1016,
  AnonymousInvocationNotAllowed = 1017,
  ModelToDtoMappingNotSupported = 1018,
  MethodAttributeMissing = 1019,
  MasterDataValueDoesNotExist = 1020,
  ApplicationSettingsInvalid = 1021,
  AccountCredentialsInvalid = 2000,
  AccountExternalCredentialsInvalid = 2001,
  AccountDoesNotExist = 2002,
  AccountLockedOut = 2003,
  AccountLockedOutOverride = 2004,
  AccountTombstoned = 2005,
  AccountCredentialsExpired = 2006,
  AccountRoleInvalidForOperation = 2007,
  AccountCredentialsNotConfirmed = 2008,
  AccountEmailAddressInvalid = 2009,
  AccountPasswordDoesNotMeetMinimumComplexity = 2010,
  AccountEmailAddressAlreadyExists = 2011,
  AccountEmailAddressOrGuidInvalid = 2012,
  AccountEmailAddressNotConfirmed = 2013,
  AccountMagicUrlTokenMissing = 2014,
  AccountMagicUrlTokenInvalid = 2015,
  AccountMagicUrlTokenExpired = 2016,
  AccountStatusInvalidForOperation = 2017,
  AccountPasswordUsedPreviously = 2018,
  AccountAlreadyConfirmed = 2019,
  AccountRequiresIdentityProviderLocal = 2020,
  AccountRequiresIdentityProviderGoogle = 2021,
  AccountRequiresIdentityProviderApple = 2022,
  AccountRequiresIdentityProviderLinkedIn = 2023,
  JwtUnknownError = 2100,
  JwtRoleInvalid = 2101,
  JwtBearerTokenInvalid = 2102,
  JwtClaimNotPresent = 2103,
  JwtBearerTokenMissing = 2104,
  JwtClaimInvalid = 2105,
  JwtBearerTokenExpired = 2106,
  JwtRefreshTokenInvalid = 2107,
  GoogleJwtBearerTokenInvalid = 2200,
  GoogleJwtNonceInvalid = 2201,
  DocumentUploadToAzureFailed = 2300,
  DocumentDownloadFromAzureFailed = 2301,
  DocumentAlreadyExists = 2302,
  CustomerInternalIDCouldNotBeGenerated = 3000,
  CustomerDoesNotExist = 3001,
  CustomerAddressBlockIncomplete = 3002,
  EmailDeliveryFailed = 4000,
  ChatCreationFailed = 5000,
  ChatAccessDenied = 5001,
  ChatOpenAiResponseEmpty = 5002,
  ChatOpenAiResponseInvalid = 5003,
  ChatOpenAiCitationInvalid = 5004,
  ChatOpenAiCitationListInvalid = 5005,
  ChatOpenAiCitationNotFound = 5006
}